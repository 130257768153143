export const periodLabelsMappings = {
  PRE_GAME: {
    label: 'Prematch',
    shortLabel: 'PM',
    position: 1,
    hasTimeValues: false,
  },
  PREMATCH: {
    label: 'Prematch',
    shortLabel: 'PM',
    position: 1,
    hasTimeValues: false,
  },
  PRE_MATCH: {
    label: 'Prematch',
    shortLabel: 'PM',
    position: 1,
    hasTimeValues: false,
  },
  TOTAL: {
    label: 'Match',
    shortLabel: 'Match',
    position: 2,
    hasTimeValues: false,
  },
  FIRST_PERIOD: {
    label: '1st quarter',
    shortLabel: '1st Q',
    position: 3,
    hasTimeValues: true,
  },
  BEFORE_SECOND_PERIOD: {
    label: 'In-break(1st-2nd quarter)',
    shortLabel: 'Break',
    position: 4,
    hasTimeValues: false,
  },
  SECOND_PERIOD: {
    label: '2nd quarter',
    shortLabel: '2nd Q',
    position: 5,
    hasTimeValues: true,
  },
  FIRST_HALF: {
    label: '1st half',
    shortLabel: '1st HT',
    position: 6,
    hasTimeValues: true,
  },
  IN_FIRST_HALF: {
    label: '1st half',
    shortLabel: '1st HT',
    position: 6,
    hasTimeValues: true,
  },
  HALF_TIME: {
    label: 'Half time',
    shortLabel: 'HT',
    position: 7,
    hasTimeValues: false,
  },
  SECOND_HALF: {
    label: '2nd half',
    shortLabel: '2nd HT',
    position: 8,
    hasTimeValues: true,
  },
  IN_SECOND_HALF: {
    label: '2nd half',
    shortLabel: '2nd HT',
    position: 8,
    hasTimeValues: true,
  },
  THIRD_PERIOD: {
    label: '3rd quarter',
    shortLabel: '3rd Q',
    position: 9,
    hasTimeValues: true,
  },
  BEFORE_THIRD_PERIOD: {
    label: 'In-break(2nd-3nd period)',
    shortLabel: 'Break',
    position: 8,
    hasTimeValues: false,
  },
  BEFORE_FOURTH_PERIOD: {
    label: 'In-break(3rd-4th quarter)',
    shortLabel: 'Break',
    position: 10,
    hasTimeValues: false,
  },
  FOURTH_PERIOD: {
    label: '4th quarter',
    shortLabel: '4th Q',
    position: 11,
    hasTimeValues: true,
  },
  BEFORE_OVERTIME: {
    label: 'In-break(before OT)',
    shortLabel: 'Break',
    position: 13,
    hasTimeValues: false,
  },
  IN_OVERTIME: {
    label: 'Overtime',
    shortLabel: 'OT',
    position: 14,
    hasTimeValues: true,
  },
  OVERTIME_1: { // used for all other Overtimes in logic
    label: 'Overtime',
    shortLabel: 'OT',
    position: 15,
    hasTimeValues: true,
  },
  POST_GAME: {
    label: 'End',
    shortLabel: 'End',
    position: 27,
    hasTimeValues: false,
  },
  GAME_ABANDONED: {
    label: 'Suspended',
    shortLabel: 'Susp',
    position: 26,
    hasTimeValues: false,
  },
  AT_HALF_TIME: {
    label: 'Half time',
    shortLabel: 'HT',
    position: 7,
    hasTimeValues: false,
  },
  IN_FIRST_PERIOD: {
    label: '1st quarter',
    shortLabel: '1st Q',
    position: 3,
    hasTimeValues: true,
  },
  IN_FIRST_QUARTER: {
    label: '1st quarter',
    shortLabel: '1st Q',
    position: 3,
    hasTimeValues: true,
  },
  IN_SECOND_PERIOD: {
    label: '2nd quarter',
    shortLabel: '2nd Q',
    position: 5,
    hasTimeValues: true,
  },
  IN_SECOND_QUARTER: {
    label: '2nd quarter',
    shortLabel: '2nd Q',
    position: 5,
    hasTimeValues: true,
  },
  IN_THIRD_PERIOD: {
    label: '3rd quarter',
    shortLabel: '3rd Q',
    position: 9,
    hasTimeValues: true,
  },
  IN_THIRD_QUARTER: {
    label: '3rd quarter',
    shortLabel: '3rd Q',
    position: 9,
    hasTimeValues: true,
  },
  IN_FOURTH_PERIOD: {
    label: '4th quarter',
    shortLabel: '4th Q',
    position: 11,
    hasTimeValues: true,
  },
  IN_FOURTH_QUARTER: {
    label: '4th quarter',
    shortLabel: '4th Q',
    position: 11,
    hasTimeValues: true,
  },
  NORMAL_TIME_COMPLETED: {
    label: 'In-break(Full time-OT)',
    shortLabel: 'Break',
    position: 12,
    hasTimeValues: false,
  },
  OVER_TIME_COMPLETED: {
    label: 'In Break(After-OT)',
    shortLabel: 'Break',
    position: 25,
    hasTimeValues: false,
  },
  FIRST: {
    label: '1st inning',
    shortLabel: '1st In',
    position: 25,
    hasTimeValues: false,
  },
  SECOND: {
    label: '2nd inning',
    shortLabel: '2nd In',
    position: 26,
    hasTimeValues: false,
  },
  THIRD: {
    label: '3rd inning',
    shortLabel: '3rd In',
    position: 27,
    hasTimeValues: false,
  },
  FOURTH: {
    label: '4th inning',
    shortLabel: '4th In',
    position: 28,
    hasTimeValues: false,
  },
  FIFTH: {
    label: '5th inning',
    shortLabel: '5th In',
    position: 29,
    hasTimeValues: false,
  },
  SIXTH: {
    label: '6th inning',
    shortLabel: '6th In',
    position: 30,
    hasTimeValues: false,
  },
  SEVENTH: {
    label: '7th inning',
    shortLabel: '7th In',
    position: 31,
    hasTimeValues: false,
  },
  EIGHTH: {
    label: '8th inning',
    shortLabel: '8th In',
    position: 32,
    hasTimeValues: false,
  },
  NINTH: {
    label: '9th inning',
    shortLabel: '9th In',
    position: 33,
    hasTimeValues: false,
  },
  TENTH: {
    label: 'Extra innings',
    shortLabel: 'Extra In',
    position: 34,
    hasTimeValues: false,
  },
};

<template>
  <div class="feed-priority-form">
    <div class="feed-priority-form__head">
      <div class="feed-priority-form__title">
        Allowed feeds
      </div>
      <Button
        variant="primary"
        :disabled="isSubmitDisabled"
        :loading="disabled"
        @click="submitForm"
      >
        Save
      </Button>
    </div>
    <div class="feed-priority-form__body">
      <div class="feed-priority-form__grid">
        <div
          v-for="(feed, index) in allFeeds"
          :key="index"
          :title="feed.label"
          class="feed-priority-form__parameter"
          @click.stop.prevent="updateAllowedFeeds(feed.value)"
        >
          <Checkbox
            class="feed-priority-form__parameter-input"
            :model-value="sources.includes(feed.value)"
            @click.stop.prevent="updateAllowedFeeds(feed.value)"
          />
          <span class="feed-priority-form__parameter-label">
            {{ feed.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  filter, isEqual, includes, orderBy,
} from 'lodash';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Button,
    Checkbox,
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    originalSources: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:sources': {
      type: Array,
    },
    submit: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const isSubmitDisabled = computed(() => props.disabled || isEqual(props.sources, props.originalSources));
    const allFeeds = computed(() => orderBy(props.options, 'label'));

    const updateAllowedFeeds = (feed) => {
      const updatedSources = includes(props.sources, feed)
        ? filter(props.sources, (source) => source !== feed)
        : [...props.sources, feed];
      emit('update:sources', updatedSources);
    };

    const submitForm = () => {
      if (isSubmitDisabled.value) return;
      emit('submit', props.sources);
    };

    return {
      isSubmitDisabled,
      submitForm,
      updateAllowedFeeds,
      allFeeds,
    };
  },
};
</script>

<style lang="scss">
.feed-priority-form {
  background: #FFF;
  border: 1px solid #DDD;
  border-radius: 4px;

  &__head {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 15.72px;
    font-weight: 600;
    line-height: 23.58px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    gap: 16px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__parameter {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 2px;

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__label {
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 11.85px;
    color: #6D6D6D;
    text-transform: uppercase;
  }
}
</style>

<template>
  <div class="suspension-flags-configurations">
    <div class="suspension-flags-configurations__header">
      <div class="suspension-flags-configurations__header-title">
        Suspension flags
      </div>
      <Button
        variant="primary"
        :disabled="isSavingDisabled"
        @click="submit"
      >
        Save
      </Button>
    </div>
    <div class="suspension-flags-configurations__content">
      <div class="suspension-flags-configurations__nav-list">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          :class="[
            'suspension-flags-configurations__nav-item',
            { 'suspension-flags-configurations__nav-item--is-active': selectedTab.id === tab.id },
          ]"
          @click="selectTab(tab)"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="tab-dropdown-wrapper">
        <div class="tab-dropdown">
          <Dropdown
            :label="selectedConfigType.label"
            :placeholder="'Select type'"
          >
            <DropdownItem
              v-for="type in configTypes"
              :key="type.id"
              clickable
              @click="selectConfigType(type)"
            >
              {{ type.label }}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
      <div
        class="suspension-configuration-table"
        v-if="selectedConfigType.id !== 'NO_CONFIG'"
      >
        <div class="suspension-configuration-table-header">
          <div
            :class="['suspension-configuration-table-column',
                     {'market-segment': column.id === 'marketSegment',
                      'suspension-reason': column.id === 'preMatchSuspensionReason' || column.id === 'inPlaySuspensionReason',
                      'segment-large': column.id === 'marketSegment' && selectedConfigType?.type !== 'priceDistance' }]"
            v-for="(column, index) in tableColumns"
            :key="index"
          >
            <span :title="column.tooltip">{{ column.label }}</span>
          </div>
        </div>
        <div
          class="suspension-configuration-table-body"
          v-if="segments?.length && selectedConfigType?.id !== 'GLOBAL_DISTANCE' && selectedConfigType?.id !== 'GLOBAL_DISTANCE_EXTENDED'"
        >
          <div
            class="suspension-configuration-table-body-row"
            v-for="(segment, index) in segments"
            :key="index"
          >
            <div
              :class="['suspension-configuration-table-body-row-column',
                       {'market-segment': column.id === 'marketSegment',
                        'suspension-reason': column.id === 'preMatchSuspensionReason' || column.id === 'inPlaySuspensionReason',
                        'segment-large': column.id === 'marketSegment' && selectedConfigType?.type !== 'priceDistance' }]"
              v-for="(column, columnIndex) in tableColumns"
              :key="columnIndex"
            >
              <div
                class="column-market-segment-label"
                v-if="column.id === 'marketSegment'"
              >
                {{ segment.label }}
              </div>
              <div
                class="column-suspension-reason"
                v-else-if="column.id === 'preMatchSuspensionReason' || column.id === 'inPlaySuspensionReason'"
              >
                <div class="suspension-dropdown">
                  <Dropdown
                    :label="displaySelectedReason(segment, column)"
                    :placeholder="'Sel...'"
                    right
                  >
                    <DropdownItem
                      v-for="(reason, reasonIndex) in suspensionReasons"
                      :key="reasonIndex"
                      clickable
                      @click="updateSuspensionReason(segment, column, reason)"
                    >
                      <div class="reason-item-group">
                        {{ reason.label }}
                        <Icon
                          v-if="displaySelectedReason(segment, column) === reason.label"
                          name="boolean-true"
                        />
                      </div>
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
              <div
                class="column-suspension-input"
                v-else-if="column.id === 'preMatchDistance' || column.id === 'inPlayDistance'"
              >
                <div class="distance-input">
                  <NumberInput
                    :model-value="displayDistanceValue(column.id, segment)"
                    @update:modelValue="(newValue) => updateSegmentDistance(newValue, segment, column.id)"
                    :min="0"
                    :readonly="inputDisabled(segment, column.id)"
                    :max="1"
                  />
                </div>
              </div>
              <div
                class="column-suspension-toggle"
                v-else-if="column.id === 'preMatchSuspensionAllowed' || column.id === 'inPlaySuspensionAllowed'"
              >
                <Toggle
                  :model-value="segment[column.id]"
                  @update:model-value="updateSuspensionAllowed($event, segment, column)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-table-row"
          v-if="selectedConfigType?.id === 'GLOBAL_DISTANCE'"
        >
          <div class="distance-input">
            <NumberInput
              :model-value="priceDistanceConfig.distance"
              @update:modelValue="updateGlobalDistance"
              :min="0"
              :max="1"
            />
          </div>
        </div>
        <div
          class="suspension-configuration-table-body"
          v-if="!segments?.length && selectedConfigType?.id === 'GLOBAL_DISTANCE_EXTENDED'"
        >
          <div
            class="suspension-configuration-table-body-row"
          >
            <div
              :class="['suspension-configuration-table-body-row-column',
                       {'suspension-reason': column.id === 'preMatchSuspensionReason' || column.id === 'inPlaySuspensionReason'}]"
              v-for="(column, columnIndex) in tableColumns"
              :key="columnIndex"
            >
              <div
                class="column-suspension-reason"
                v-if="column.id === 'preMatchSuspensionReason' || column.id === 'inPlaySuspensionReason'"
              >
                <div class="suspension-dropdown">
                  <Dropdown
                    :label="displaySelectedReason('global_extended', column)"
                    :placeholder="'Sel...'"
                    right
                  >
                    <DropdownItem
                      v-for="(reason, reasonIndex) in suspensionReasons"
                      :key="reasonIndex"
                      clickable
                      @click="updateSuspensionReason('global_extended', column, reason)"
                    >
                      <div class="reason-item-group">
                        {{ reason.label }}
                        <Icon
                          v-if="displaySelectedReason('global_extended', column) === reason.label"
                          name="boolean-true"
                        />
                      </div>
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
              <div
                class="column-suspension-input"
                v-else-if="column.id === 'preMatchDistance' || column.id === 'inPlayDistance'"
              >
                <div class="distance-input">
                  <NumberInput
                    :model-value="displayDistanceValue(column.id, 'global_extended')"
                    @update:modelValue="(newValue) => updateSegmentDistance(newValue, 'global_extended', column.id)"
                    :min="0"
                    :readonly="inputDisabled('global_extended', column.id)"
                    :max="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import {
  cloneDeep, isEqual, each, isNil,
  assign, map, isEmpty, find,
} from 'lodash';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import NumberInput from '@/components/common/NumberInput';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';
import {
  suspensionFlagsTabs, generateSportMarketSegments, suspensionFlagsConfigurationsPerType,
  createDistanceColumns, createPriceColumns,
} from '@/services/helpers/sport-template';

export default {
  components: {
    Button,
    Dropdown,
    DropdownItem,
    NumberInput,
    Icon,
    Toggle,
  },
  props: {
    sportLabel: {
      type: String,
      required: true,
    },
    priceDistanceConfiguration: {
      type: Object,
      required: true,
    },
    arbitrageConfiguration: {
      type: Object,
      required: true,
    },
    topPriceConfiguration: {
      type: Object,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    submit: {},
  },
  setup(props, { emit }) {
    const tabs = suspensionFlagsTabs();

    const priceDistanceConfig = ref(cloneDeep(props.priceDistanceConfiguration));
    const arbitrageConfig = ref(cloneDeep(props.arbitrageConfiguration));
    const topPriceConfig = ref(cloneDeep(props.topPriceConfiguration));

    const updateGlobalDistance = (value) => {
      if (!value) return;
      priceDistanceConfig.value.distance = Number(value);
    };

    const marketSegments = generateSportMarketSegments(props.sportLabel);

    const selectedTab = ref(tabs[0]);

    const configTypes = ref(suspensionFlagsConfigurationsPerType[selectedTab.value?.configurationType]);

    const selectedConfigType = ref({});
    const selectConfiguration = () => {
      if (selectedTab.value?.configurationType === 'priceDistanceConfiguration') {
        selectedConfigType.value = find(configTypes.value, { id: priceDistanceConfig.value?.type }) || configTypes.value[0];
      } else if (selectedTab.value?.configurationType === 'arbitrageConfiguration') {
        selectedConfigType.value = find(configTypes.value, { id: arbitrageConfig.value?.type }) || configTypes.value[0];
      } else {
        selectedConfigType.value = find(configTypes.value, { id: topPriceConfig.value?.type }) || configTypes.value[0];
      }
    };
    selectConfiguration();

    const tableColumns = computed(() => {
      if (selectedConfigType.value?.type === 'priceDistance') {
        if (selectedConfigType.value?.id === 'GLOBAL_DISTANCE') return createDistanceColumns('simple');
        if (selectedConfigType.value?.id === 'GLOBAL_DISTANCE_EXTENDED') return createDistanceColumns('extended');
        return createDistanceColumns();
      }
      return createPriceColumns();
    });

    const isDistanceInvalid = computed(() => {
      if (priceDistanceConfig.value?.type === 'GLOBAL_DISTANCE') {
        if (isNil(priceDistanceConfig.value?.distance)) return true;
      }
      if (priceDistanceConfig.value?.type !== 'GLOBAL_DISTANCE' && priceDistanceConfig.value?.type !== 'NO_CONFIG') {
        if (isNil(priceDistanceConfig.value?.inPlayDistance) && isNil(priceDistanceConfig.value?.preMatchDistance)) return true;
      }
      return false;
    });

    const isArbitrageInvalid = computed(() => {
      if (priceDistanceConfig.value?.type === 'PER_MARKET_SEGMENT_ARBITRAGE') {
        if (isNil(arbitrageConfig.value?.inPlaySuspensionAllowed) && isNil(arbitrageConfig.value?.preMatchSuspensionAllowed)) return true;
      }
      return false;
    });

    const isTopPriceInvalid = computed(() => {
      if (topPriceConfig.value?.type === 'PER_MARKET_SEGMENT_TOP_PRICE') {
        if (isNil(topPriceConfig.value?.inPlaySuspensionAllowed) && isNil(topPriceConfig.value?.preMatchSuspensionAllowed)) return true;
      }
      return false;
    });

    const isSavingDisabled = computed(() => (props.updating || (isDistanceInvalid.value || isArbitrageInvalid.value || isTopPriceInvalid.value)
        || (isEqual(props.priceDistanceConfiguration, priceDistanceConfig.value)
        && isEqual(props.arbitrageConfiguration, arbitrageConfig.value)
        && isEqual(props.topPriceConfiguration, topPriceConfig.value))));

    const selectTab = (tab) => {
      selectedTab.value = tab;
      configTypes.value = suspensionFlagsConfigurationsPerType[selectedTab.value?.configurationType];
      selectConfiguration();
    };

    const selectConfigType = (type) => {
      if (selectedConfigType.value.id === type.id) return;
      selectedConfigType.value = type;
      if (type.type === 'priceDistance') {
        priceDistanceConfig.value = {
          type: type.id,
        };
      } else if (type.type === 'arbitragePrice') {
        arbitrageConfig.value = {
          type: type.id,
        };
      } else {
        topPriceConfig.value = {
          type: type.id,
        };
      }
    };

    const formatPriceDistanceConfigForSubmit = () => {
      if (!priceDistanceConfig.value?.type) {
        priceDistanceConfig.value = {
          type: 'NO_CONFIG',
        };
      }
      if (priceDistanceConfig.value?.type === 'GLOBAL_DISTANCE_EXTENDED') {
        if (isNil(priceDistanceConfig.value?.preMatchDistance)) {
          priceDistanceConfig.value = {
            ...priceDistanceConfig.value,
            preMatchDistance: {
              isSuspensionAllowed: false,
              suspendIfNoPrices: false,
              distance: 0,
            },
          };
        }
        if (isNil(priceDistanceConfig.value?.inPlayDistance)) {
          priceDistanceConfig.value = {
            ...priceDistanceConfig.value,
            inPlayDistance: {
              isSuspensionAllowed: false,
              suspendIfNoPrices: false,
              distance: 0,
            },
          };
        }
      }
      if (priceDistanceConfig.value?.type === 'GLOBAL_DISTANCE') {
        if (isNil(priceDistanceConfig.value?.distance)) {
          priceDistanceConfig.value = {
            ...priceDistanceConfig.value,
            distance: 0,
          };
        }
      }
      if (priceDistanceConfig.value?.type === 'PER_MARKET_SEGMENT_DISTANCE') {
        if (isNil(priceDistanceConfig.value?.preMatchDistance)) {
          priceDistanceConfig.value.preMatchDistance = {};
        }
        if (isNil(priceDistanceConfig.value?.inPlayDistance)) {
          priceDistanceConfig.value.inPlayDistance = {};
        }
      }
    };

    const formatArbitrageConfigForSubmit = () => {
      if (!arbitrageConfig.value?.type) {
        arbitrageConfig.value = {
          type: 'NO_CONFIG',
        };
      }
      if (arbitrageConfig.value?.type === 'PER_MARKET_SEGMENT_ARBITRAGE') {
        if (isNil(arbitrageConfig.value?.preMatchSuspensionAllowed)) {
          arbitrageConfig.value = {
            ...arbitrageConfig.value,
            preMatchSuspensionAllowed: {},
          };
        }
        if (isNil(arbitrageConfig.value?.inPlaySuspensionAllowed)) {
          arbitrageConfig.value = {
            ...arbitrageConfig.value,
            inPlaySuspensionAllowed: {},
          };
        }
      }
    };

    const formatTopPriceConfigForSubmit = () => {
      if (!topPriceConfig.value?.type) {
        topPriceConfig.value = {
          type: 'NO_CONFIG',
        };
      }
      if (topPriceConfig.value?.type === 'PER_MARKET_SEGMENT_TOP_PRICE') {
        if (isNil(topPriceConfig.value?.preMatchSuspensionAllowed)) {
          topPriceConfig.value = {
            ...topPriceConfig.value,
            preMatchSuspensionAllowed: {},
          };
        }
        if (isNil(topPriceConfig.value?.inPlaySuspensionAllowed)) {
          topPriceConfig.value = {
            ...topPriceConfig.value,
            inPlaySuspensionAllowed: {},
          };
        }
      }
    };

    const submit = () => {
      if (isSavingDisabled.value) return;
      formatPriceDistanceConfigForSubmit();
      formatArbitrageConfigForSubmit();
      formatTopPriceConfigForSubmit();
      emit('submit', {
        priceDistanceConfiguration: priceDistanceConfig.value,
        arbitrageConfiguration: arbitrageConfig.value,
        topPriceConfiguration: topPriceConfig.value,
      });
    };

    const segments = computed(() => {
      const mappedMarketSegments = cloneDeep(marketSegments);
      if (selectedConfigType.value?.id === 'NO_CONFIG' || selectedConfigType.value?.id === 'GLOBAL_DISTANCE' || selectedConfigType.value?.id === 'GLOBAL_DISTANCE_EXTENDED') {
        return [];
      }
      if (selectedConfigType.value?.type === 'priceDistance') {
        each(mappedMarketSegments, (seg) => {
          if (!isNil(priceDistanceConfig.value?.inPlayDistance?.[seg.id])) {
            assign(seg, { inPlayDistance: priceDistanceConfig.value.inPlayDistance[seg.id] });
          }
          if (!isNil(priceDistanceConfig.value?.preMatchDistance?.[seg.id])) {
            assign(seg, { preMatchDistance: priceDistanceConfig.value.preMatchDistance[seg.id] });
          }
          if (isNil(priceDistanceConfig.value?.inPlayDistance?.[seg.id]) && isNil(priceDistanceConfig.value?.preMatchDistance?.[seg.id])) {
            assign(seg, {
              inPlayDistance: {},
              preMatchDistance: {},
            });
          }
        });
      }
      if (selectedConfigType.value?.type === 'arbitragePrice') {
        each(mappedMarketSegments, (seg) => {
          if (!isNil(arbitrageConfig.value?.inPlaySuspensionAllowed?.[seg.id])) {
            assign(seg, { inPlaySuspensionAllowed: arbitrageConfig.value.inPlaySuspensionAllowed[seg.id] });
          }
          if (!isNil(arbitrageConfig.value?.preMatchSuspensionAllowed?.[seg.id])) {
            assign(seg, { preMatchSuspensionAllowed: arbitrageConfig.value.preMatchSuspensionAllowed[seg.id] });
          }
          if (isNil(arbitrageConfig.value?.inPlaySuspensionAllowed?.[seg.id]) && isNil(arbitrageConfig.value?.preMatchSuspensionAllowed?.[seg.id])) {
            assign(seg, {
              inPlaySuspensionAllowed: false,
              preMatchSuspensionAllowed: false,
            });
          }
        });
      }
      if (selectedConfigType.value?.type === 'topPrice') {
        each(mappedMarketSegments, (seg) => {
          if (!isNil(topPriceConfig.value?.inPlaySuspensionAllowed?.[seg.id])) {
            assign(seg, { inPlaySuspensionAllowed: topPriceConfig.value.inPlaySuspensionAllowed[seg.id] });
          }
          if (!isNil(topPriceConfig.value?.preMatchSuspensionAllowed?.[seg.id])) {
            assign(seg, { preMatchSuspensionAllowed: topPriceConfig.value.preMatchSuspensionAllowed[seg.id] });
          }
          if (isNil(topPriceConfig.value?.inPlaySuspensionAllowed?.[seg.id]) && isNil(topPriceConfig.value?.preMatchSuspensionAllowed?.[seg.id])) {
            assign(seg, {
              inPlaySuspensionAllowed: false,
              preMatchSuspensionAllowed: false,
            });
          }
        });
      }
      return map(mappedMarketSegments);
    });

    const suspensionReasons = ref([
      {
        id: '1',
        label: 'Don\'t suspend at all',
      },
      {
        id: '2',
        label: 'Distance is too large',
      },
      {
        id: '3',
        label: 'No prices or distance is too large',
      },
    ]);

    const updateSuspensionReason = (segment, column, reason) => {
      const key = column.id === 'preMatchSuspensionReason' ? 'preMatchDistance' : 'inPlayDistance';
      if (segment === 'global_extended') {
        if (!priceDistanceConfig.value?.[key]) {
          priceDistanceConfig.value[key] = {};
        }
        if (reason.id === '1') {
          priceDistanceConfig.value[key].isSuspensionAllowed = false;
          priceDistanceConfig.value[key].suspendIfNoPrices = false;
          if (!isNil(priceDistanceConfig.value[key].distance)) delete priceDistanceConfig.value[key].distance;
        } else if (reason.id === '2') {
          priceDistanceConfig.value[key].isSuspensionAllowed = true;
          priceDistanceConfig.value[key].suspendIfNoPrices = false;
        } else {
          priceDistanceConfig.value[key].isSuspensionAllowed = true;
          priceDistanceConfig.value[key].suspendIfNoPrices = true;
        }
      } else {
        if (!priceDistanceConfig.value?.[key]?.[segment.id]) {
          priceDistanceConfig.value[key] = {
            ...priceDistanceConfig.value[key],
            [segment.id]: {},
          };
        }
        if (reason.id === '1') {
          priceDistanceConfig.value[key][segment.id].isSuspensionAllowed = false;
          priceDistanceConfig.value[key][segment.id].suspendIfNoPrices = false;
          if (!isNil(priceDistanceConfig.value[key][segment.id].distance)) delete priceDistanceConfig.value[key][segment.id].distance;
        } else if (reason.id === '2') {
          priceDistanceConfig.value[key][segment.id].isSuspensionAllowed = true;
          priceDistanceConfig.value[key][segment.id].suspendIfNoPrices = false;
        } else {
          priceDistanceConfig.value[key][segment.id].isSuspensionAllowed = true;
          priceDistanceConfig.value[key][segment.id].suspendIfNoPrices = true;
        }
      }
    };

    const displayDistanceValue = (columnId, segment) => {
      if (segment === 'global_extended') {
        return priceDistanceConfig.value[columnId]?.distance;
      }
      return segment[columnId]?.distance;
    };

    const updateSegmentDistance = (value, segment, column) => {
      if (segment === 'global_extended') {
        if (!priceDistanceConfig.value[column]) {
          priceDistanceConfig.value[column] = {};
        }
        priceDistanceConfig.value[column].distance = !value ? 0 : Number(value);
      } else
      if (selectedConfigType.value?.type === 'priceDistance') {
        if (!priceDistanceConfig.value[column]?.[segment.id]) {
          priceDistanceConfig.value[column][segment.id] = {};
        }
        priceDistanceConfig.value[column][segment.id].distance = !value ? 0 : Number(value);
      }
    };

    const displaySelectedReason = (segment, column) => {
      let selectedState = {};
      if (segment === 'global_extended') {
        selectedState = column.id === 'preMatchSuspensionReason' ? priceDistanceConfig.value.preMatchDistance : priceDistanceConfig.value.inPlayDistance;
      } else {
        selectedState = column.id === 'preMatchSuspensionReason' ? segment.preMatchDistance : segment.inPlayDistance;
      }
      if (isEmpty(selectedState)) return '';
      if (!selectedState.isSuspensionAllowed && !selectedState.suspendIfNoPrices) {
        return suspensionReasons.value[0].label;
      }
      if (selectedState.isSuspensionAllowed && !selectedState.suspendIfNoPrices) {
        return suspensionReasons.value[1].label;
      }
      return suspensionReasons.value[2].label;
    };

    const inputDisabled = (segment, column) => {
      let selectedState = {};
      if (segment === 'global_extended') {
        selectedState = column === 'preMatchDistance' ? priceDistanceConfig.value.preMatchDistance : priceDistanceConfig.value.inPlayDistance;
      } else {
        selectedState = column === 'preMatchDistance' ? segment.preMatchDistance : segment.inPlayDistance;
      }
      return isEmpty(selectedState) || !selectedState.isSuspensionAllowed;
    };

    const updateSuspensionAllowed = (value, segment, column) => {
      if (selectedConfigType.value?.type === 'arbitragePrice') {
        if (!arbitrageConfig.value?.[column.id]?.[segment.id]) {
          arbitrageConfig.value[column.id] = {
            ...arbitrageConfig.value[column.id],
            [segment.id]: false,
          };
        }
        arbitrageConfig.value[column.id][segment.id] = value;
      } else {
        if (!topPriceConfig.value?.[column.id]?.[segment.id]) {
          topPriceConfig.value[column.id] = {
            ...topPriceConfig.value[column.id],
            [segment.id]: false,
          };
        }
        topPriceConfig.value[column.id][segment.id] = value;
      }
    };

    watch(() => props.priceDistanceConfiguration, (newValue, oldValue) => {
      if (isEqual(newValue, oldValue)) return;
      priceDistanceConfig.value = cloneDeep(newValue);
    });

    watch(() => props.arbitrageConfiguration, (newValue, oldValue) => {
      if (isEqual(newValue, oldValue)) return;
      arbitrageConfig.value = cloneDeep(newValue);
    });

    watch(() => props.topPriceConfiguration, (newValue, oldValue) => {
      if (isEqual(newValue, oldValue)) return;
      topPriceConfig.value = cloneDeep(newValue);
    });

    return {
      selectedTab,
      tabs,
      isSavingDisabled,
      selectTab,
      submit,
      selectedConfigType,
      configTypes,
      selectConfigType,
      marketSegments,
      tableColumns,
      segments,
      updateGlobalDistance,
      suspensionReasons,
      updateSuspensionReason,
      updateSegmentDistance,
      displaySelectedReason,
      inputDisabled,
      updateSuspensionAllowed,
      priceDistanceConfig,
      displayDistanceValue,
    };
  },
};
</script>

<style lang="scss">
.suspension-flags-configurations {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 4px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;

    &-title {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Poppins', sans-serif;
      font-size: 15.72px;
      font-weight: 600;
      line-height: 23.58px;
      color: #191414;

      .tooltip {
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.22px;
      }

      .icon {
        svg {
          g {
            path {
              stroke: #CDCDCD;
            }
          }
        }
      }
    }
  }

  &__content {
    padding: 0 16px 16px;
  }

  &__nav {
    &-list {
      display: flex;
      margin-bottom: 8px;
      border-bottom: 2px solid #F0F0F0;
    }

    &-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      line-height: 16px;
      border-bottom: 2px solid #F0F0F0;
      margin-bottom: -2px;
      min-width: 45px;
      cursor: pointer;

      &--is-active {
        border-bottom: 2px solid #003C3C;
      }
    }
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 16px;
    color: #191414;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__parameter {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 2px;

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tab-dropdown-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .label {
      color: #A9A9A9;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 4px;
      display: flex;
    }

    .tab-dropdown {
      width: 100%;

      .dropdown {
        z-index: unset;

        .dropdown-button {
          border-color: #DDDDDD;
        }
      }

      .dropdown-button {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .suspension-configuration-table {
    width: 100%;
    .suspension-configuration-table-header {
      display: flex;
      height: 32px;
      width: 100%;
      background-color: #FAFAFA;
      border-bottom: 1px solid #F0F0F0;
      font-size: 12px;
      color: #6D6D6D;
      padding: 0 8px;
      .suspension-configuration-table-column {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0;

        span {
          max-width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.market-segment {
          width: 76px;
          min-width: 76px;
        }

        &.suspension-reason {
          min-width: 56px;
        }

        &.segment-large {
          min-width: 180px;
        }
      }
    }

    .suspension-configuration-table-body {
      width: 100%;
      display: flex;
      flex-direction: column;

      .suspension-configuration-table-body-row {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F0F0F0;
        padding: 0 8px;

        .suspension-configuration-table-body-row-column {
          display: flex;
          height: 100%;
          align-items: center;
          flex: 1;
          min-width: 0;

          &.market-segment {
            width: 76px;
            min-width: 76px;
          }

          &.suspension-reason {
            min-width: 56px;
          }

          &.segment-large {
            min-width: 180px;
          }
        }
      }
    }

    .suspension-dropdown {
      width: 56px;
      max-width: 56px;

      .dropdown {
        z-index: unset;

        .dropdown-button {
          border-color: #DDDDDD;
        }
      }

      .dropdown-button {
        width: 100%;
        justify-content: space-between;
      }

      .reason-item-group {
        display: flex;
        height: 100%;
        align-items: center;
        gap: 4px;
      }
    }

    .no-table-row {
      display: flex;
      align-items: center;
      height: 44px;
      font-size: 14px;
      padding: 0 8px;
    }

    .distance-input {
      display: flex;

      .table-number-input {
        width: 56px;

        input {
          padding: 0 8px;
          text-align: center;
          border-color: #DDDDDD;
          height: 33px;
        }
      }
    }
    .column-suspension-toggle {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <div class="included-markets__root">
    <div class="search-container">
      <TextInput
        v-model="search"
        placeholder="Search markets"
        icon="search"
        small
      />
    </div>
    <div class="markets-multiple-select-header">
      <CheckboxDropdown
        :actions="marketGroupesMultipleSelectOptions"
        :selected-items-length="numberOfSelectedMarketGroups"
        :rows-length="filteredMarketGroups.length"
        :multiselect="true"
        @selected:value="(value) => marketGroupToggled(value)"
      />
      <p>Configure multiple markets at once</p>
    </div>
    <div
      class="markets-container__scrolbar"
      ref="marketsContainerScroll"
      @scroll="onMarketsScroll"
    >
      <div
        v-for="marketGroup in filteredMarketGroups"
        :key="marketGroup.groupCode"
        class="market-group__container"
      >
        <div class="group-item">
          <Checkbox
            :intermediate="marketGroupIntermediate(marketGroup.groupCode)"
            :model-value="isMarketGroupApplied(marketGroup.groupCode)"
            @update:modelValue="marketGroupToggled(marketGroup.groupCode)"
          />
          <p>
            {{ marketGroup.groupName }}
          </p>
        </div>
        <div
          v-for="market in findMarketsByGroup(marketGroup.groupCode)"
          :key="market.marketTemplateId"
          class="market-item"
          @mouseover="setHoveredMarket(market.marketTemplateId)"
          @mouseleave="setHoveredMarket(null)"
        >
          <div class="market-info">
            <Checkbox
              @update:model-value="(value) => marketToggled(market, value)"
              :model-value="isMarketSelected(market)"
            />
            <div>
              <p class="market-name">
                {{ market.name }}
              </p>
              <p
                v-if="!isNil(market.enabled)"
                :class="['market-status ', getMarketTemplateStatusType(market.marketTemplateId)]"
              >
                {{ formatMarketTemplateStatusType(getMarketTemplateStatusType(market.marketTemplateId)) }}
              </p>
            </div>
          </div>
          <Dropdown
            v-if="isMarketHovered(market)"
            :class="['market-item-more-dropdown ']"
            icon="more-dots"
            hide-chevron
            right
          >
            <DropdownItem
              @click="configureMarket(market)"
              clickable
            >
              Configure
            </DropdownItem>

            <DropdownItem
              @click="toggleMarketEnabled(market)"
              clickable
              :disabled="isNil(market.enabled)"
            >
              {{ market.enabled ? 'Disable' : 'Enable' }}
            </DropdownItem>

            <DropdownItem
              class="dropdown-item--danger"
              @click="removeMarket(market)"
              clickable
            >
              Remove
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onUnmounted, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import {
  uniqBy, filter, reduce, map, includes, forEach, find, orderBy,
  toLower, isNil, flatMap, every, some, debounce,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Checkbox from '@/components/common/Checkbox';
import CheckboxDropdown from '@/components/common/CheckboxDropdown';

export default {
  components: {
    TextInput,
    Dropdown,
    DropdownItem,
    Checkbox,
    CheckboxDropdown,
  },
  props: {
    markets: {
      type: Array,
      default: () => [],
    },
    marketGroups: {
      type: Array,
      default: () => [],
    },
    originalSportTemplate: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['onConfigureMarket', 'onRemoveMarket', 'onMarketEnabledToggled'],
  setup(props, { emit }) {
    const store = useStore();
    const search = ref('');
    const hoveredMarket = ref();
    const marketsTopScrollPosition = computed(() => store.getters.marketTemplateMarketsScrollPosition || 0);
    const uniqueMarketTemplates = computed(() => uniqBy(props.markets, 'marketTemplateId'));
    const selectedMarkets = computed(() => store.getters.selectedMarkets);
    const getMarketsByCode = (marketCode) => filter(uniqueMarketTemplates.value, { marketCode });
    const isMarketSelected = (market) => !!find(selectedMarkets.value, { marketTemplateId: market.marketTemplateId });
    const marketsWithGroups = computed(() => flatMap(props.marketGroups, (group) => (reduce(
      group.markets.nodes,
      (allMarkets, market) => [
        ...allMarkets,
        ...map(getMarketsByCode(market.marketCode), (m) => ({
          ...m,
          groupName: group.displayName,
          groupCode: group.code,
        })),
      ], [],
    ))));
    const marketsWithoutGroups = computed(() => {
      const marketCodesWithGroups = map(marketsWithGroups.value, 'marketTemplateId');
      const withoutGroups = filter(props.markets, (market) => !includes(marketCodesWithGroups, market.marketTemplateId));
      return map(withoutGroups, (market) => ({
        ...market,
        groupCode: 'UNGROUPED',
        groupName: 'Ungrouped',
      }));
    });
    const allMarkets = computed(() => uniqBy([
      ...orderBy(marketsWithGroups.value, 'position'),
      ...orderBy(marketsWithoutGroups.value, 'position'),
    ], 'marketTemplateId'));
    const filteredMarkets = computed(() => filter(
      allMarkets.value,
      (market) => (
        includes(toLower(market.name), toLower(search.value))
        || includes(toLower(market.groupName), toLower(search.value))
      ),
    ));
    const filteredMarketGroups = computed(() => uniqBy(map(filteredMarkets.value, ({ groupName, groupCode }) => ({ groupName, groupCode })), 'groupCode'));

    const findMarketsByGroup = (code) => filter(filteredMarkets.value, { groupCode: code });
    const setHoveredMarket = (marketTemplateId) => { hoveredMarket.value = marketTemplateId; };
    const isMarketHovered = (market) => hoveredMarket.value === market.marketTemplateId;

    const configureMarket = (market) => emit('onConfigureMarket', market);
    const toggleMarketEnabled = (market) => { emit('onMarketEnabledToggled', market, !market.enabled); };
    const removeMarket = (market) => {
      emit('onRemoveMarket', [{ ...market, included: false }]);
    };

    const getMarketTemplateStatusType = (marketTemplateId) => {
      const templates = filter(props.markets, { marketTemplateId });
      const isEveryEnabled = every(templates, { enabled: true });
      const isSomethingEnabled = some(templates, { enabled: true });
      if (isEveryEnabled) return 'ENABLED';
      if (isSomethingEnabled) return 'PARTIALLY_ENABLED';
      return 'DISABLED';
    };
    const formatMarketTemplateStatusType = (statusType) => {
      if (statusType === 'ENABLED') return 'Enabled';
      if (statusType === 'PARTIALLY_ENABLED') return 'Partially enabled';
      return 'Disabled';
    };

    const selectMarket = (market) => {
      if (isMarketSelected(market)) return;
      const sportsLogicTemplateIds = map(filter(props.markets, { marketTemplateId: market.marketTemplateId }), ({ sportsLogicTemplateId }) => sportsLogicTemplateId);
      store.dispatch('updateSelectedMarkets', [
        ...store.getters.selectedMarkets,
        {
          marketTemplateId: market.marketTemplateId,
          sportsLogicTemplateIds,
          isLineMarket: market.templateConfiguration.type === 'WITH_LINE',
        },
      ]);
    };
    const unselectMarket = (market) => {
      store.dispatch('updateSelectedMarkets', filter(
        store.getters.selectedMarkets,
        ({ marketTemplateId }) => market.marketTemplateId !== marketTemplateId,
      ));
    };

    const marketToggled = (market, value) => {
      if (value) {
        selectMarket(market);
      } else {
        unselectMarket(market);
      }
    };

    const isMarketGroupApplied = (groupCode) => {
      const marketsByGroup = findMarketsByGroup(groupCode);
      const appliedMarkets = filter(marketsByGroup, (item) => includes(map(selectedMarkets.value, ({ marketTemplateId }) => marketTemplateId), item.marketTemplateId));
      return marketsByGroup.length === appliedMarkets.length;
    };

    const marketGroupIntermediate = (groupCode) => {
      const marketsByGroup = findMarketsByGroup(groupCode);
      const appliedMarkets = filter(marketsByGroup, (item) => includes(map(selectedMarkets.value, ({ marketTemplateId }) => marketTemplateId), item.marketTemplateId));
      return marketsByGroup.length !== appliedMarkets.length && appliedMarkets.length > 0;
    };

    const marketGroupesMultipleSelectOptions = computed(() => map(filteredMarketGroups.value, ({ groupName, groupCode }) => ({
      key: groupCode,
      label: groupName,
      selected: isMarketGroupApplied(groupCode),
    })));

    const numberOfSelectedMarketGroups = computed(() => reduce(
      filteredMarketGroups.value,
      (count, group) => (isMarketGroupApplied(group.groupCode) ? count + 1 : count),
      0,
    ));

    const marketGroupToggled = (groupCode) => {
      if (groupCode === 'all') {
        if (numberOfSelectedMarketGroups.value === filteredMarketGroups.value.length) {
          store.dispatch('updateSelectedMarkets', []);
        } else {
          const allMarketsToToggle = flatMap(filteredMarketGroups.value, (group) => findMarketsByGroup(group.groupCode));
          forEach(allMarketsToToggle, (m) => marketToggled(m, true));
        }
      }
      const regionApplied = isMarketGroupApplied(groupCode);
      const originalMarketGroups = findMarketsByGroup(groupCode);
      forEach(originalMarketGroups, (m) => {
        if (regionApplied) {
          marketToggled(m, false);
        } else {
          marketToggled(m, true);
        }
      });
    };

    const marketsContainerScroll = ref(null);

    const debounceSaveScrollPosition = debounce((scrollTop) => {
      store.dispatch('saveMarketTemplateMarketsScrollPosition', scrollTop);
    }, 500);

    const onMarketsScroll = () => {
      const { scrollTop } = marketsContainerScroll.value;
      debounceSaveScrollPosition(scrollTop);
    };

    const scrollToMarketsHeight = () => {
      marketsContainerScroll.value.scrollTo({
        top: marketsTopScrollPosition.value || 0,
        behavior: 'smooth',
      });
    };

    onMounted(() => {
      scrollToMarketsHeight();
    });

    onUnmounted(() => {
      store.dispatch('updateSelectedMarkets', []);
    });

    return {
      isNil,
      search,
      marketsWithGroups,
      marketsWithoutGroups,
      filteredMarkets,
      filteredMarketGroups,
      hoveredMarket,
      marketGroupesMultipleSelectOptions,
      numberOfSelectedMarketGroups,
      setHoveredMarket,
      isMarketHovered,
      toggleMarketEnabled,
      removeMarket,
      configureMarket,
      getMarketsByCode,
      findMarketsByGroup,
      getMarketTemplateStatusType,
      formatMarketTemplateStatusType,
      isMarketGroupApplied,
      marketGroupToggled,
      marketToggled,
      isMarketSelected,
      marketsContainerScroll,
      onMarketsScroll,
      marketGroupIntermediate,
    };
  },
};
</script>
<style lang="scss">
.included-markets__root {
  padding: 13px 0;
  height: calc(100% - 42px);

  .search-container {
    margin-bottom: 16px;
  }
  .markets-multiple-select-header {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    display: flex;
    align-items: center;
    background-color: $gray300;
    padding: 16px;
    color: $gray800;
    gap: 6px;
  }

  .markets-container__scrolbar {
    height: calc(100% - 49px - 64px);
    overflow-y: auto;

    .market-group__container {
      margin-bottom: 30px;
      padding-left: 4.5px;
      .group-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-top: 8px;
        p {
            margin-left: 8px;
        }
      }
    }

    .market-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0 8px 12px;
      border-left: 1px solid $gray400;

      .market-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      .market-name {
        font-size: 14px;
        color: $black;
      }
      .market-status {
        font-size: 12px;
        color: $gray700;
        &.PARTIALLY_ENABLED {
          color: #ffc531;
        }
        &.DISABLED {
          color: $error500;
        }
      }

      &:hover .market-item-more-dropdown {
        display: block;
      }
      .market-item-more-dropdown {
        display: none;
        position: relative;
        z-index: 1;

        .button {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
        }
        .dropdown-item--danger {
          color: $error500;
        }
      }
    }
  }
}
</style>
